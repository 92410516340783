(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/on-events/assets/javascripts/on-events.js') >= 0) return;  svs.modules.push('/components/lb-utils/on-events/assets/javascripts/on-events.js');
"use strict";

function onEvent(eventName, cb) {
  const handleEvent = value => {
    cb(value);
  };
  window.addEventListener(eventName, handleEvent);

  return () => window.removeEventListener(eventName, handleEvent);
}
function publishEvent(eventName, data) {
  requestIdleCallback(() => {
    const customEvent = new CustomEvent(eventName, data);
    window.dispatchEvent(customEvent);
  });
}
setGlobal('svs.components.lbUtils.onEvents', {
  onEvent,
  publishEvent
});

 })(window);