(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/on-events/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/lb-utils/on-events/assets/javascripts/constants.js');
"use strict";


const eventNames = Object.freeze({
  PAYMENT_SUCCESS: 'payment-success',
  COMPETITION_SIGN_UP: 'competition-sign-up'
});
setGlobal('svs.components.lbUtils.onEvents', {
  eventNames
});

 })(window);